import { createTheme } from '@mui/material/styles';
import { yellow } from '@mui/material/colors';
import { alpha } from "@mui/material/styles";


declare module '@mui/material/styles' {

  interface CommonColors {
    stacBlue?: string;
    navy?: string;
    yellowBackground?: string;
    yellowCardBackground?: string;
    greyCardBackground?: string;
    darkGreyText?: string;
    lightGrey?:string;
    lightBlue?: string;
    lightNavy?: string;
  }

  interface Palette {
    tertiary?: Palette['primary'];
    yellowCard: Palette['primary'];
  }

  interface PaletteOptions {
    tertiary?: PaletteOptions['primary'];
    yellowCard?: PaletteOptions['primary'];
  }

}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    tertiary: true;
  }
}
declare module '@mui/material/Card' {
  interface CardPropsColorOverrides {
    yellowBackground: true;
  }
}


const stacBlue = '#2861E2'; // Old: '#2374F5';
const blueVariant = '#2374F5';
const navy = '#0B2652';
const lightBlue= '#90CAF9'
const lightNavy= '#5C7EC8'
const grey = "#F9F9F9";
const lightGrey = '#737373'
const darkGrey = '#CECECE';
const darkGreyBackground = '#E8E8E8';

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 320,
      md: 640,
      lg: 1040,
      xl: 1440
    }
  },
  palette: {
    common: {
      stacBlue,
      navy,
      yellowBackground: yellow[800],
      yellowCardBackground: '#FFB335',
      greyCardBackground: darkGreyBackground,
      darkGreyText: darkGrey,
      lightGrey: lightGrey,
      lightBlue: lightBlue,
      lightNavy: lightNavy,
    },
    background: { default: grey },
    primary: {
      main: stacBlue,
      light: blueVariant
    },
    secondary: {
      main: '#F5A623',
      light: '#F7BC2B'
    },
    error: {
      main: '#d81B60' // Old: '#E5009C'
    },
    info :{
      main: navy
    },
    text: {
      primary: navy
    },
    tertiary: {
      main: '#FFFFFF',
      contrastText: navy,
    },
    yellowCard: {
      main: '#FFB335',
      contrastText: navy
    }
  },
  typography: {
    fontWeightBold: 500,
    h6: {
      fontWeight: 500
    },
    overline: {
      fontWeight: 500
    }
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: alpha(darkGrey, .5)
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          textTransform: "none",
        },
        contained: {
          borderRadius: 16,
          '&:hover': {
            backgroundColor: blueVariant,
            color: 'white'
          },
          '&:focus': {
            backgroundColor: blueVariant,
            color: 'white'
          },
        },
        outlined: {
          borderRadius: 16,
          color: navy,
          borderColor: navy,
          '&:hover': {
            borderColor: stacBlue,
            color: stacBlue
          },
          '&:focus': {
            borderColor: stacBlue,
            color: stacBlue
          },
          '&:Mui-disabled': {
            borderColor: navy,
            color: navy
          }
        },
        text: {
          color: navy,
          letterSpacing: '1.5px',
          fontWeight: 500,
          '&:hover': {
            backgroundColor: 'inherit',
            textDecoration: 'underline'
          },
          '&:Mui-focusVisible': {
            color: navy,
            border: '1px',
            borderColor: stacBlue,
            textDecoration: 'underline',
            backgroundColor: 'inherit',
          },
          '&:Mui-disabled': {
            color: navy,
            backgroundColor: 'inherit',
          },
        },
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderRadius: 4,
          borderColor: darkGreyBackground
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: grey
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        asterisk: {
          display: "none"
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "black"
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "black",
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: stacBlue,
        },
        barColorPrimary: {
          backgroundColor: grey,
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: "white",
          color: "black"
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          background: "white",
        },
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: 4
        },
      }
    },
    MuiTab: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          '&.Mui-selected': {
            color: navy
          },
          ...(ownerState['aria-selected'] === 'false' && {
              color: darkGrey
            }),
        })
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          ":hover": {
            backgroundColor: '#aee8ff',
            cursor: 'pointer'
          },
          "&:last-child td, &:last-child th": {
            border: 0
          }
        },
      }
    },
  },
});
